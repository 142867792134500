import { Features, Features1, Features2, SectionWrapper } from "./components";
import assets from "./assets";

const App = () => {
  return (
    <>
      <SectionWrapper
        title="Join us 
          at mission
          to save Resources"
        description="Explore our app here."
        showBtn
        mockupImg={
          assets.homeHero
        } /*colocar outro, este é feio, foi feito à pressa, apenas para testar*/
        banner="banner"
        first={true}
      />

      <SectionWrapper
        title="Our mission"
        h1="Reduce costs and ecological footprint,
          our biggest challenge.
          For a better plane" /*não está a funcionar */
        description="Our mission originated from the
          problems we identified in the area of energy efficiency.
          Wanting to take this a step further, we decided to create an application that would help people to save not only energy, 
          but also other resources, such as water, food, transportation and recycling.
          The most recent energy crisis, lack of information and 
          lack of motivation are one of the constraints that make the population not know how to save in these areas."
        mockupImg={assets.scene} /*substituir pelos novos mockups*/
        reverse /*"prop." especial, não esquecer de colocar na componente*/
      />

      <SectionWrapper
        title="About the App"
        description="Our application focuses on informing users through a forum and motivating with challenges that help and guide users to save more and change bad habits, depending on the needs of each one."
        mockupImg={
          assets.homeCards
        } /*colocar outro, este é feio, foi feito à pressa, apenas para testar*/
        banner="banner04"
      />

      <Features />
      <Features2 reverse />
      <Features1 />
    </>
  );
};

export default App;
