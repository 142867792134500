import React from "react";

import assets from "../assets";
import styles from "../styles/Global";

const FeatureCard = ({ iconUrl, iconText }) => (
  <div className={styles.featureCard}>
    <img src={iconUrl} alt="icon" className={styles.featureImg} />
    <p className={styles.featureText}>{iconText}</p>
  </div>
);

const Features = () => {
  return (
    <div className={`${styles.section} ${styles.bgPrimary} banner`}>
      <div className={`${styles.subSection} flex-col text-center`}>
        <div>
          <h1 className={`${styles.h1Text} ${styles.whiteText}`}>
            The Circles
          </h1>
          <div className={`${styles.descriptionText} ${styles.whiteText}`}>
            What are Circles?{" "}
          </div>
          <p className={`${styles.pText} ${styles.whiteText}`}>
            Each circle will be a community on our forum. You will be able to
            select the circles according to what you want challenged.
          </p>
        </div>

        <div className={styles.flexWrap}>
          <FeatureCard iconUrl={assets.icon1} iconText="" />
          <FeatureCard iconUrl={assets.icon2} iconText="" />
          <FeatureCard iconUrl={assets.icon3} iconText="" />
          <FeatureCard iconUrl={assets.icon4} iconText="" />
          <FeatureCard iconUrl={assets.icon5} iconText="" />
        </div>
      </div>
    </div>
  );
};

export default Features;
