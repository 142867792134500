import React from "react";

import assets from "../assets";
import styles from "../styles/Global";

const FeatureCard = ({ iconUrl, iconText }) => (
  <div className={styles.featureCard}>
    <img src={iconUrl} alt="icon" className={styles.featureImg} />
    <p className={styles.featureText}>{iconText}</p>
  </div>
);

const Features1 = () => {
  return (
    <div className={`${styles.section}`}>
      <div className={`${styles.subSection} flex-col text-center`}>
        <div>
          <h1 className={`${styles.h1Text} ${styles.blackText}`}>
            Team Hominere
          </h1>
        </div>

        <div className={styles.flexWrap}>
          <FeatureCard iconUrl={assets.icon6} iconText="Maria Sarrato" />
          <FeatureCard iconUrl={assets.icon7} iconText="Daniel Figueiredo" />
          <FeatureCard iconUrl={assets.icon8} iconText="Joana Cunha" />
          <FeatureCard iconUrl={assets.icon9} iconText="Marta Fradique" />
          <FeatureCard iconUrl={assets.icon10} iconText="Diogo Cruz" />
        </div>
      </div>
    </div>
  );
};

export default Features1;
