import React from "react";
import styles from "../styles/Global";

const Features2 = () => {
  return (
    <div className={`${styles.section}`}>
      <div className={`${styles.subSection} flex-col text-center`}>
        <iframe
          width="600"
          height="355"
          src="https://www.youtube.com/embed/Uo7u4MekzHY"
          title="YouTube video player"
        ></iframe>
      </div>
    </div>
  );
};

export default Features2;
