import homeHero from "./home_hero.png";
import homeCards from "./home_cards.png";
import hominere from "./hominere.png";
import icon1 from "./icon1.png";
import icon2 from "./icon2.png";
import icon3 from "./icon3.png";
import icon4 from "./icon4.png";
import icon5 from "./icon5.png";
import icon6 from "./icon6.png";
import icon7 from "./icon7.png";
import icon8 from "./icon8.png";
import icon9 from "./icon9.png";
import icon10 from "./icon10.png";
import scene from "./scene.png";

const images = {
  homeHero,
  homeCards,
  hominere,
  icon1,
  icon2,
  icon3,
  icon4,
  icon5,
  icon6,
  icon7,
  icon8,
  icon9,
  icon10,
  scene,
};

export default images;
